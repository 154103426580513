@import "../variables.scss";

.menu-container {
    .kitchenmenu-options {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        min-height: 0;
        gap: 4px;

        .kitchenmenu-option-container {
            padding: .25rem 0;
            display: flex;            
            flex-direction: column;
            
            span {
                font-size: 1em;
                font-weight: 500;
                text-transform: uppercase;              
            }

            .kitchenmenu-option-list {
                display: flex;
                gap: 4px;

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                    border: 1px solid rgba(165,165,165,1);
                    opacity: 1;
                    transition: opacity .2s ease-out;
                } 
                
                .kitchenmenu-option-wrapper {
                    display: flex;
                    flex-direction: column;
                    flex: 0 1 49%;

                    span {
                        display: flex;
                        flex: 1;
                    }
                }

                .kitchenmenu-option {
                    position: relative;
                    flex: 0 1 32%;

                    &.toggle {
                        flex: 0 1 49%;
                        img {
                            height: 100px;
                        }
                    }

                    .menu-img-wrapper {
                        position: relative;
                        width: 100%;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            border: 1px solid #000;
                            transition: opacity .2s ease-out;
                            opacity: 0;
                        }
                    }

                    span {
                        padding: 1em;
                        position: absolute;                   
                        bottom: 10px;
                        width: 100%;
                        opacity: 0;
                        font-size: 0.75em;
                        background-color: rgba(255, 255, 255, 0.6);
                        transition: opacity .2s ease-out;
                        word-break: break-word;

                        @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {           
                            padding: .25em;
                        }
                    }

                    &:hover,
                    &.selected {
                        .menu-img-wrapper {
                            &:after {
                                opacity: 1;
                            }
                        }
        
                        img {
                            opacity: 0.4;
                        }
        
                        span {
                            opacity: 1;
                        }
                    }

                }
            }
        }
    }
}