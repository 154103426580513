.vedum-configurator {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
  .vedum-container {
      height: 100%;
      overflow: hidden;
      position: relative;
      margin: auto;
      max-height: 100%;
  }
}