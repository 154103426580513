@import "../variables.scss";

.menu-toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 150px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0;
    background: rgba(200, 200, 200, 1);
    cursor: pointer;

    @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
        width: 100px;
        height: 53px;
    }

    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);

    span,
    svg {
        opacity: 0.6;
        transition: opacity .2s ease-out;       
    }

    svg {
        @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
            font-size: 1.25em;
        }
    }

    span {
        margin-top: 1rem;

        @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {
            margin-top: 0.25rem;
        }
    }

    &:hover {

        span,
        svg {
            opacity: 1;
        }
    }
}

.menu-wrapper {
    position: absolute;
    left: 150px;
    height: 100%;
    display: flex;

    @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
        left: 100px;
    }
}

.menu-container,
.menu-sub-container {
    position: relative;
    z-index: 11;
    width: 275px;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
        width: 220px;
    }

    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);

    button {
        display: flex;
    }

    .menu-close {
        position: absolute;
        right: -30px;
        height: 30px;
        width: 30px;
        top: 0;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        background-color: rgba(255, 255, 255, 0.6);
        cursor: pointer;
    }

    .menu-back {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: transparent;

        span {
            line-height: 1.5rem;
            font-size: 1.25em;
            font-weight: 500;
            text-transform: uppercase;

            @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
                font-size: 1em;
            }
        }
    }

    .menu-options {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        min-height: 0;
        gap: 4px;

        button {
            background: transparent;
        }

        .menu-img-wrapper {
            position: relative;
            width: 100%;

            img {
                width: 100%;
                height: auto;
               // border: 1px solid rgba(165, 165, 165, 1);
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                border: 1px solid #000;
                transition: opacity .2s ease-out;
                opacity: 0;
            }
        }

        .menu-option-header {
            flex: 1 1 100%;
            font-size: 1.2em;
            font-weight: 500;
            text-transform: uppercase;

            @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
                font-size: 1em;
            }
        }

        .menu-main {
            flex: 1 0 auto;
            align-items: center;

            .menu-img-wrapper {
                width: auto;
                
                img {                    
                    min-width: 75px;
                    width: 75px;
                    height: auto;
                    opacity: 0;
                    transition: opacity .2s ease-out;
                    display: block;

                    @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
                        min-width: 60px;
                        width: 60px;
                        height: auto;
                    }

                    &.show {
                        opacity: 1;
                    }
                }
            }

            span {
                margin-left: 1em;
                font-size: 1.25em;
                font-weight: 500;
                text-transform: uppercase;
                text-align: left;

                @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
                    font-size: 1em;
                }

            }

            &:hover,
            &.selected {
                .menu-img-wrapper {
                    &:after {
                        opacity: 1;
                    }
                }

                img {
                    opacity: 0.4;
                }

                span {
                    opacity: 1;
                }
            }
        }


        .menu-option {
            position: relative;
            flex: 0 1 auto;
            //flex: 1;                            

            img {              
                min-width: 78px;
                width: 78px;
                height: auto;
                opacity: 0;
                transition: opacity .2s ease-out;
                display: block;

                @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
                    min-width: 60px;
                    width: 60px;
                    height: auto;
                }

                &.show {
                    opacity: 1;
                }
            }

            span {
                padding: 1em;
                position: absolute;
                bottom: 10px;
                width: 100%;
                opacity: 0;
                font-size: 0.75em;
                text-transform: uppercase;
                background-color: rgba(255, 255, 255, 0.6);
                transition: opacity .2s ease-out;
                word-break: break-word;               
            }

            &:hover,
            &.selected {
                .menu-img-wrapper {
                    &:after {
                        opacity: 1;
                    }
                }

                img {
                    opacity: 0.4;
                }

                span {
                    opacity: 1;
                }
            }
        }

        .menu-preset {
            margin-bottom: 10px;
            flex: 1 0 auto;
            flex-direction: column;

            img {
                opacity: 1;
                transition: opacity .2s ease-out;
                display: block;
            }

            span {
                padding: .25rem 0;
                display: flex;
                font-size: 1.25em;
                font-weight: 500;
                text-transform: uppercase;

                @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {           
                    overflow-y: scroll;
                    font-size: 1em;
                }
            }


            &:hover,
            &.selected {
                .menu-img-wrapper {
                    &:after {
                        opacity: 1;
                    }
                }

                img {
                    opacity: 0.4;
                }

                span {
                    opacity: 1;
                }
            }
        }

        &.configurable-select,
        &.option-select {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}

.menu-sub-container {
    padding: 10px;
   // box-shadow: 6px 0 9px rgba(0, 0, 0, 0.2) inset;
   // -webkit-box-shadow: 6px 0 9px rgba(0, 0, 0, 0.2) inset;
   // -moz-box-shadow: 6px 0 9px rgba(0, 0, 0, 0.2) inset;
    z-index: 10;

    .menu-back {
        span {
            line-height: 1.5rem;
        }
    }

}