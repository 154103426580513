@import "../variables.scss";

@keyframes turn {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

.mobile-turn {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255,255,255,0.8);   
    display: none;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1000;

    @media screen and (orientation: portrait) {
        display: flex;
    }

    .mobile-turn-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
            width: 24px;
            animation: turn infinite 2s ease-out;
        }

        span {
            padding: 0.5rem 0;
            font-weight: 500;
            font-size: 1.25em;
        }
    }
}