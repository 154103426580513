@import "../variables.scss";

.price {   
    display: flex;
    flex-direction: column; 
    color: rgba(255,255,255,1);

    .price-value {
        padding: 1rem 0rem 0 0rem;
        font-size: 1.75em;
        display: flex;
        flex-direction: column;
        background-color: rgba(35,32,34,1);
        text-align: center;

        @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
            font-size: 1em;
        }

        span {
            margin-bottom: 0.5rem;
        }        
    }

    .book {
        padding: 1rem 0;
        display: block;
        background-color: rgba(0,0,0,1);
        color: rgba(255,255,255,1);
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        font-size: 1em;

        @media screen and (max-width: $tabletportrait), screen and (max-height: 414px) and (orientation: landscape) {      
            font-size: 0.65em;
        }

        span {           
            margin-left: 0.5rem;
        }
    }

}